<template>
  <div v-if="disabledModal" @click="toggleSuccess" class="success-modal">
    <div class="content text-center">
      <b-icon class="fc-fff" scale="3" icon="check-circle"></b-icon>
      <h3 class="fc-fff mt-4">تم حفظ القطعة في قائمة المفضلة</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessModal',
  props: {
    disabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      success: this.disabled
    }
  },
  watch: {
    disabled(val) {
      this.success = val
    }
  },
  computed: {
    disabledModal() {
      return this.success
    }
  },
  components: {

  },
  methods: {
    toggleSuccess() {
      this.success = !this.success
    }
  }
}
</script>
