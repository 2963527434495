<template>
  <b-container fluid class="search-content p-100">
    <b-row class="mt-4 z-index-10000">
      <b-col>
        <b-button class="font-sfns text-right note-primary-btn" :to="{name: 'plot', params: {plotNo: this.$route.params.plotId}, query:{plan: $route.query.plan, coords: $route.query.coords, meter_sq: $route.query.meter_sq}}" pill block variant="primary">{{ $route.query.plan }}</b-button>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col class="text-right">
        <h4>طلب تعديل معلومة</h4>
      </b-col>
    </b-row>
    <div class="note-cover mt-4">
      <textarea v-model="comment" class="text-right" placeholder="اضف ملاحظاتك هنا…."></textarea>
      <input type="file" class="d-none" ref="uploadInput" @change="updateCurrInput">
      <b-button :disabled="dataInputFile != ''" @click="$refs.uploadInput.click()" class="feedback-att-btn mb-3" pill variant="light">ارفاق ملف أو صورة <img :src="attach" /></b-button>
      <b-button :disabled="btnDisabled" @click="addFeedback" class="text-center note-sub-btn" size="lg" pill block variant="success">حفظ2</b-button>
    </div>
    <b-row class="mt-4" v-if="dataInputFile != ''">
      <b-col md="8">
        {{ $t('attached_file') }}
      </b-col>
      <b-col md="4">
        <b-icon class="cursor-pointer" @click="dataInputFile = ''" style="color:#f00;" scale="2" icon="x"></b-icon>
      </b-col>
    </b-row>
    <success-modal :disabled="disabledModal" />
  </b-container>
</template>

<script>
import SuccessModal from '@/components/SuccessModal.vue'

export default {
  title: "اضف تعليق",
  data() {
    return {
      height: "18px",
      comment: "",
      success: false,
      dataInputFile: "",
      attach: require("@/assets/images/icons/attach.svg"),
      city_id: "",
      cities: []
    }
  },
  components: {
    SuccessModal
  },
  watch: {
    '$route.query'() {
      this.fetchPlot()
    },
    success(val) {
      this.success = val
    }
  },
  computed: {
    plot() {
      return this.$store.state.plan.plot
    },
    disabledModal() {
      return this.success
    },
    btnDisabled() {
      return this.comment == ""
    },
  },
  methods: {
    toggleSuccess() {
      this.success = true
    },
    fetchPlot() {
      let loader = this.$loading.show({opacity: 0.9})
      const payload = {
        meta: {
          planNo: this.$route.query.plan,
          plotNo: this.$route.params.plotId
        }
      }
      this.$store.dispatch("plan/fetchPlot", payload)
      .then(() => {
        loader.hide()
      })
    },
    updateCurrInput(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = () => {
          this.dataInputFile = input.target.files[0]
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    createNew() {
      let loader = this.$loading.show({opacity: 0.9})

      let form_data = new FormData()
      if (this.dataInputFile != "") {
        form_data.append('feedback_file', this.dataInputFile, this.dataInputFile.name)
      }
      form_data.append('plan_no', this.$route.query.plan)
      form_data.append('plot_no', this.$route.params.plotId)
      form_data.append('comment', this.comment)
      form_data.append('city_id', this.city_id)

      this.$store.dispatch("plan/addFeedback", form_data)
      .then(() => {
        this.comment = ""
        this.dataInputFile = ""
        this.toggleSuccess()
        loader.hide()
      })
      .catch(() => {
        loader.hide()
      })
    },
    fetchCities() {
      let loader = this.$loading.show({opacity: 0.9})
      const payload = {
         meta: {
           page: this.currentPage,
           paginate: this.pagination
         }
      }
      this.$store.dispatch("city/fetchCities", payload)
      .then((res) => {
        this.cities = res.data.data
        let cityIndex = this.cities.findIndex(city => Math.abs(parseFloat(city.coords.split(",")[0] - parseFloat(this.$route.query.coords.split(",")[0]))) < 0.1)
        if(cityIndex != -1) {
          this.city_id = this.cities[cityIndex]["id"]
          this.createNew()
        }
        loader.hide()
      })
      .catch(() => {
        loader.hide()
      })
    },
    addFeedback() {
      let loader = this.$loading.show({opacity: 0.9})

      let form_data = new FormData()
      if (this.dataInputFile != "") {
        form_data.append('feedback_file', this.dataInputFile, this.dataInputFile.name)
      }
      let city_id = ""
      if (this.plot.city != undefined) {
        city_id = this.plot.city[0]["id"]
      }
      form_data.append('plan_no', this.plot.plan_id)
      form_data.append('plot_no', this.plot.id)
      form_data.append('comment', this.comment)
      form_data.append('city_id', city_id)

      this.$store.dispatch("plan/addFeedback", form_data)
      .then(() => {
        this.comment = ""
        this.dataInputFile = ""
        this.toggleSuccess()
        loader.hide()
      })
      .catch(() => {
        loader.hide()
        this.fetchCities()
      })
    }
  },
  created() {
    this.fetchPlot()
  },
  updated() {
  }
}
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 100vh;
}
</style>
